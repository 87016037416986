// custom code added by Codeable 
document.addEventListener("DOMContentLoaded", function() {
	
    function getTranslateValues (element) {
	  const style = window.getComputedStyle(element)
	  const matrix = style['transform'] || style.webkitTransform || style.mozTransform

	  // No transform property. Simply return 0 values.
	  if (matrix === 'none' || typeof matrix === 'undefined') {
		return {
		  x: 0,
		  y: 0,
		  z: 0
		}
	  }

	  // Can either be 2d or 3d transform
	  const matrixType = matrix.includes('3d') ? '3d' : '2d'
	  const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')

	  // 2d matrices have 6 values
	  // Last 2 values are X and Y.
	  // 2d matrices does not have Z value.
	  if (matrixType === '2d') {
		return {
		  x: matrixValues[4],
		  y: matrixValues[5],
		  z: 0
		}
	  }

	  // 3d matrices have 16 values
	  // The 13th, 14th, and 15th values are X, Y, and Z
	  if (matrixType === '3d') {
		return {
		  x: matrixValues[12],
		  y: matrixValues[13],
		  z: matrixValues[14]
		}
	  }
	}
	
	function getSpeedFactor(element){

		const speedFactorXL = element.dataset.cdblSpeedXl;
		const speedFactorLG = element.dataset.cdblSpeedLg;
		const speedFactorMD = element.dataset.cdblSpeedMd;
		const speedFactorSM = element.dataset.cdblSpeedSm;
		const speedFactorXS = element.dataset.cdblSpeedXs;

		const checkXS = window.matchMedia('screen and (max-width: 576px)');
		const checkSM = window.matchMedia('screen and (max-width: 768px) and (min-width: 577px)');
		const checkMD = window.matchMedia('screen and (max-width: 992px) and (min-width: 769px)');
		const checkLG = window.matchMedia('screen and (max-width: 1200px) and (min-width: 993px)');
		const checkXL = window.matchMedia('screen and (min-width: 1201px)');
		
		let speedFactor = element.dataset.cdblSpeed ? element.dataset.cdblSpeed : "1";
		if(checkXS.matches){
			speedFactor = speedFactorXS ? speedFactorXS : speedFactor ;
			console.log(speedFactor, ' checkXS = ' + checkXS, speedFactorXS);
		}else if(checkSM.matches ){
			speedFactor = speedFactorSM ? speedFactorSM : speedFactor ;
			console.log(speedFactor, ' checkSM = ' + checkSM);
		}else if(checkMD.matches ){
			speedFactor = speedFactorMD ? speedFactorMD : speedFactor ;
			console.log(speedFactor, ' checkMD = ' + checkMD);
		}else if(checkLG.matches ){
			speedFactor = speedFactorLG ? speedFactorLG : speedFactor ;
			console.log(speedFactor, ' checkLG = ' + checkLG);
		}else if(checkXL.matches){
			speedFactor = speedFactorXL ? speedFactorXL : speedFactor ;
			console.log(speedFactor, ' checkXL = ' + checkXL);
		}
			
		 return Number(speedFactor);
	}
	
	// Get all the elements to be parallaxed
    // 
    //const parallaxFooterElements = document.querySelectorAll('#wrapper-footer .parallax-shape');
	//const parallaxContentElements = document.querySelectorAll('.parallax-shape:not(#wrapper-footer .parallax-shape)');
	const parallaxElements = document.querySelectorAll('.parallax-shape');

    const parallax = elements => {
        if ('undefined' !== elements && elements.length > 0) {
            elements.forEach( element => {
                let y = document.body.scrollHeight - (window.scrollY + document.documentElement.clientHeight);
				let speedFactor = getSpeedFactor(element);
                element.style.transform = 'translate3d(0, ' + ( speedFactor * 0.25 * y ) + 'px ,0)';
            })
        }
    }
	
	const contentParallax = elements => {
        if ('undefined' !== elements && elements.length > 0) {
            elements.forEach( element => {
				let speedFactor = getSpeedFactor(element);
				const rect = element.getBoundingClientRect();
				const translateValues = getTranslateValues(element);
                let y = rect.top - translateValues.y;
                element.style.transform = 'translate3d(0, ' + ( speedFactor * 0.2 * y) + 'px ,0)';
            })
        }
    }
    
//     Calculate 3d positions
    function calculateParallax() {
		//parallax(parallaxFooterElements)
		//contentParallax(parallaxContentElements)
		contentParallax(parallaxElements)
	}
// 	run the function
	requestAnimationFrame( calculateParallax );
	
    //Call the function on scroll
    window.onscroll = () => {
        requestAnimationFrame( calculateParallax );
    }
	
	// Call the funtions on resize
	window.onresize = () => {
		requestAnimationFrame( calculateParallax );
    }
	
	
});


//Mixitup now in its own file
